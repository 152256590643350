import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet.gridlayer.googlemutant';
import React, { useEffect, useRef, useState } from 'react';
import { Feature, featureCollection, multiPolygon, MultiPolygon, polygon, Polygon, Point } from '@turf/turf';
import './App.css';

type ProductData = {
  color: string;
  name: string;
  product_id: any;
};

type SpotCheck = {
  spot_id: string;
  geometry: Point;
  products: { product_id: string }[];
};

type RecommendationData = {
  multi_field?: {
    assignments: {
      field_id: string;
      property_id: string;
      products: { product_id: string }[];
    }[];
    geometry: {
      properties: {
        id: string;
        name: string;
        fields: {
          geometry: Polygon | MultiPolygon;
          id: string;
          name: string;
        }[];
      }[];
    };
  };
  spot_checks?: SpotCheck[];
  products: ProductData[];
};

/**
 * Create tool tip for a spot check.
 */
const spotCheckHtml = (name: string, colors: string[], latlng: L.LatLng) => {
  const leftColumn = document.createElement('div');
  leftColumn.style.display = 'grid';
  for (const color of colors) {
    const colorDiv = document.createElement('div');
    colorDiv.style.backgroundColor = color;
    leftColumn.appendChild(colorDiv);
  }

  const rightColumn = document.createElement('div');
  rightColumn.style.paddingRight = '5px';
  rightColumn.textContent = name;

  const content = document.createElement('div');
  content.style.color = 'black';
  content.style.display = 'grid';
  content.style.gridTemplateColumns = '15px 1fr';
  content.style.gap = '8px';
  content.appendChild(leftColumn);
  content.appendChild(rightColumn);

  return content;
}

const App: React.FC = () => {
  const mapRef = useRef<L.Map | undefined>(undefined);
  const [tilesLoaded, setTilesLoaded] = useState(false);
  const [zoomEnded, setZoomEnded] = useState(false);
  const [products, setProducts] = useState<ProductData[]>([]);

  useEffect(() => {
    const script = document.createElement('script');
    // script.async = true;
    // script.defer = true;
    script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyDBZQbagZ3iiTOhbint2riEv5kRGKFhrpg';
    document.body.appendChild(script);
    return () => {
      script.remove();
    };
  }, []);

  useEffect(() => {
    mapRef.current = L.map('mapId', { attributionControl: false, zoomControl: false }).setView([51.505, -0.09], 13);

    L.gridLayer
      .googleMutant({
        type: 'hybrid',
        minZoom: 3,
        maxZoom: 16,
		// SEED-8394 hide the points of interest and transit (train/bus/airport) markers
        styles: [
			{ featureType: 'poi', stylers: [{ visibility: 'off' }] },
			{ featureType: 'transit', stylers: [{ visibility: 'off' }] }
		],
      })
      .on('loading', () => {
        setTilesLoaded(false);
      })
      .on('load', () => {
        setTilesLoaded(true);
      })
      .addTo(mapRef.current);
	L.control.scale({ position: 'topleft' }).addTo(mapRef.current);
    return () => {
      mapRef.current?.remove();
    };
  }, []);

  useEffect(() => {
    const map = mapRef.current;
    if (!map)
      return;

    const geoJsonLayer = L.geoJSON(undefined, {
      pointToLayer: (feat, latlng) => {
        // Must convert to string, .bindTooltip can't use straight 'feature.properties.attribute'
        const label = spotCheckHtml(String(feat.properties.name), feat.properties.colors as string[], latlng);
        return L.marker(latlng, { opacity: 0 })
          .bindTooltip(label, {
            permanent: true,
            direction: 'center',
            opacity: 1,
            className: 'spot-check-tooltip'
          })
          .openTooltip();
      }
    });

    // Attach function to window for clients to execute when they want to draw items on the map.
    (window as any).execute = (rec: RecommendationData) => {
      // Clear all layers.
      geoJsonLayer.clearLayers();
      geoJsonLayer.removeFrom(map);
      
      // This element is used to track completion of the draw operation.
      // We remove it at the start and re-add it once the operation is complete.
      setZoomEnded(false);
      const completed = window.document.getElementById('complete');
      if (completed) {
        completed.remove();
      }

      // Set products so we can render legend.
      const products = rec.products;
      setProducts(products);

      // Draw fields.
      if (rec.multi_field) {
        const assignments = rec.multi_field.assignments;
        const fields = rec.multi_field.geometry.properties.flatMap(p => p.fields);

        const styler: any = (feature: Feature) => {
          const defaultFillColor = '#ffffff';

          const fieldId = feature?.properties?.id || '';
          const assignment = assignments.find(a => a.field_id === fieldId);
          const product = products.find(p => p.product_id === assignment?.products[0]?.product_id);

          // If there is a product assignment, colorize by the value
          return {
            color: '#ffffff',
            opacity: 1,
            fillColor: product?.color ?? defaultFillColor,
            weight: 1,
            fillOpacity: 0.4
          };
        };
        
        const fieldsData: Feature<MultiPolygon | Polygon, {
          id: string;
          name: string;
        }>[] = fields.map(f => {
          if (f.geometry.type === 'MultiPolygon') {
            return multiPolygon(f.geometry.coordinates, { id: f.id, name: f.name });
          } else {
            return polygon(f.geometry.coordinates, { id: f.id, name: f.name });
          }
        });
        const collection = featureCollection(fieldsData);
        geoJsonLayer.addData(collection);

        geoJsonLayer.setStyle(styler);
      }

      // Draw spot checks.
      if (rec.spot_checks) {
        const points = rec.spot_checks.map((spot, i) => {
          const colors = products.filter(p => spot.products.some(sp => sp.product_id === p.product_id)).map(p => p.color);
          return {
            ...spot.geometry,
            properties: {
              name: `Spot_${i}`,
              colors: colors,
            }
          };
        });
        for(const point of points) {
          geoJsonLayer.addData(point);
        }
      }

      // Add to the map and zoom to the correct bounds.
      geoJsonLayer.addTo(map);
      map.fitBounds(geoJsonLayer.getBounds(), {
        maxZoom: map.getMaxZoom()
      });
    };

    // Once all draw operations are completed, trigger the creation of
    // the completed HTML element which clients will be waiting for.
    map.on('moveend', () => {
      setZoomEnded(true);
    });

    return () => {
      map.clearAllEventListeners();
    };
  }, []);

  // Manage the creation of the 'completed' div.
  useEffect(() => {
    if (tilesLoaded && zoomEnded) {
      const waiting = setTimeout(() => {
        const node = document.createElement('div');
        node.id = 'complete';
        window.document.body.appendChild(node);
      }, 1000);
      return () => {
        clearTimeout(waiting);
      };
    }
  }, [tilesLoaded, zoomEnded]);

  return (
    <div id='mapId' style={{ width: '100%', height: '100%' }}>
      {/* 7F at the end of background color is for 50% opacity */}
      <div id='legend' style={{ background: '#14151C7F', borderRadius: 5, display: 'flex', flexDirection: 'column', padding: 20, position: 'absolute', right: 20, top: 20, zIndex: 800 }}>
        {products.map((p, i) => (
          <div key={p.product_id} style={{ alignItems: 'center', display: 'flex', flexDirection: 'row', marginBottom: i === products.length - 1 ? 0 : 4 }}>
            <div
              style={{
                backgroundColor: p.color,
                border: '1px solid white',
                borderRadius: 4,
                height: 18,
                marginRight: 5,
                width: 18
              }}
            />
            <p style={{ color: 'white', fontSize: 12, fontWeight: 500, margin: 0 }}>{p.name}</p>
          </div>))}
      </div>
    </div>
  );
}

export default App;
